<template>
	<div class="pp_container">
		<h2>Jumpy Block Jump Privacy Policy</h2>
		<p>Thank you for choosing to play Jumpy Block Jump! We understand that privacy is important to you, and we want you to feel comfortable and secure when using our app. This Privacy Policy explains how we collect, use, and protect your personal information in relation to our game.</p>

		<p><b>Data Collection</b></p>

		<p>Our game does not collect any personal information or data from our users. We do not store or share any information that can identify you, including your name, email address, or phone number. However, our game does contain advertisements from Google AdSense.</p>

		<p><b>Google AdSense</b></p>

		<p>We use Google AdSense to display ads in our game. Google AdSense is a program that allows us to display ads from third-party advertisers on our app. These ads may collect information about your interests, location, or other information, but they do not collect any personally identifiable information.</p>

		<p>When you interact with an ad in our game, you will be directed to a third-party website or app. We have no control over the information collected by these third parties and we are not responsible for their privacy practices. We recommend that you review the privacy policies of these third-party websites and apps before providing any personal information.</p>

		<p><b>Cookies</b></p>

		<p>Our game does not use cookies to collect any information from our users.</p>
		
		<p><b>Children’s Privacy</b></p>
		
		<p>Our game is not intended for children under the age of 13. We do not knowingly collect any personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to remove that information from our servers immediately.</p>
		
		<p><b>Changes to this Privacy Policy</b></p>
		
		<p>We reserve the right to change this Privacy Policy at any time. If we make any material changes to this Privacy Policy, we will notify you by posting the updated policy on our website. Your continued use of our app after the revised Privacy Policy has been posted indicates your acceptance of the updated terms.</p>
		
		<p><b>Contact Us</b></p>
		
		<p>If you have any questions or concerns about this Privacy Policy, please contact us at ah.goose.software@gmail.com.</p>
	</div>
</template>
<script type="text/javascript">
	export default {
		name: 'BlockJumpPrivacyPolicy'
	}
</script>
<style>
	.pp_container {
		width: 100%;
		max-width: 700px;
		margin: 5px auto;
		text-align: left;
		font-size: 1.5rem;
	}
</style>